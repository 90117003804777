/* eslint-disable */
import React, {createContext, useEffect, useState} from 'react';
import {getToken} from '../utility/jwtUtil';
import {ROLES} from '../constants/roles';
import {jwtDecode} from 'jwt-decode';

const getUserInfoFromToken = (token) => {
    const errorResult = {
        role: ROLES.NONE,
        id: undefined,
        schoolId: undefined,
        domain: undefined,
        permissions: []
    };

    if (!token) {
        return errorResult;
    }

    try {
        const decodedToken = jwtDecode(token);

        if (!decodedToken) {
            return errorResult;
        }

        return {
            role: decodedToken.role,
            id: decodedToken.sub,
            schoolId: decodedToken.SchoolId,
            domain: decodedToken.Domain,
            permissions: decodedToken.Permission ?? [],
            schoolLogoUrl: `${process.env.REACT_APP_BACKEND_URL}/school/${decodedToken.SchoolId}/logo`
        };
    } catch (e) {
        console.error(e);
    }

    return errorResult;
};

export const UserContext = createContext(null);

export const UserProvider = ({children}) => {
    const [token, setToken] = useState(() => getToken());
    const [user, setUser] = useState(() => getUserInfoFromToken(token));

    useEffect(() => {
        setUser(getUserInfoFromToken(token));
    }, [token]);

    return (
        <UserContext.Provider value={{
            user,
            onLogin: () => setToken(getToken()),
            onLogout: () => setToken(''),
            onRefresh: () => setToken(getToken())
        }}>
            {children}
        </UserContext.Provider>
    );
};
